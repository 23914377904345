jQuery( document ).ready(function($) {
    $('.apple-btn').magnificPopup({
        items: {
            src: '#apple-popup',
            type: 'inline'
        }
    });

    let SMEPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
          let hash;
          let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
          for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            this.url_vars.push(hash[0]);
            this.url_vars[hash[0]] = hash[1];
              }
          this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
          this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
          this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
          this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
          this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
          if (typeof callback === 'function') {
            callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
          }
        }
    };
        
      /* String state
        *  The status of the pre-save.
        *  Int ae_member_id
        *  The Appreciation Engine member ID. This can be passed to the forms processor.
        *  String spotify_user
        *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
        *  String deezery_user
        *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
        */
    SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
        // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
        if (state === 'success' || state === 'thank-you') {
            $.magnificPopup.open({
                items: {
                  src: '#thank-you',
                  type: 'inline'
                }
            });
        }
    });
});

document.addEventListener('musickitloaded', function () {
  
    let AmkPlaylists = [];
    let AmkAlbums = ["1552739369"];
    // Create a new SMEAppleMusic object with your parameters
    let sm = new SMEAppleMusic({
      ae: {
        ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
        brand_id: '3443823',
        segment_id: '1113825',
        activities: '{"actions":{"presave":55475},"mailing_list_optins":{"a0S61000001YpzEEAS":55477}}'
      },
      am: {
        dev_token: '',
        save_mode: 'library',
        custom_playlist_name: '',
        resources: {
          playlists: AmkPlaylists,
          albums: AmkAlbums
        }
      },
      sf: {
        form: '104530',
        default_mailing_list: 'a0S61000001YpzEEAS'
      },
      smf: {
        campaign_id: '104530',
        campaign_key: '8d51acc366927c900727c5342cfeab7a'
      }
    });
  
  document.getElementById('apple_music_form').addEventListener('submit', function (e) {
    e.preventDefault();
    // Grab the email address value
    const email = document.getElementById('apple_music_email_address').value;
    // Pass the email address and opt in status
    // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
    sm.doActions(email, true).then(function (res) {
      // Complete - handle your logic here
      $.magnificPopup.open({
        items: {
          src: '#thank-you',
          type: 'inline'
        }
      });
    }).catch(function (err) {
      // Error - handle your logic here
    });
  });
});
